import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateAssetsPageSize, UpdateAssetsSearchQuery, UpdateAssetsActivePage, UpdateAssetsSort, ResetAssetsState, UpdateAssetFilters, UpdateAssetTypes } from '@state-management/actions';

import { FlagDates, SortEntries } from '@data';

import { AssetFilters } from '@models/assets';
import { TableState } from '@models/shared';
import { AssetTemplate } from '@models/templates';

export interface AssetsStateModel extends TableState {
    filters: AssetFilters,
    assetTypes: AssetTemplate[];
}

const ASSETS_STATE_DEFAULT: AssetsStateModel = {
    filters: {
        status: 'active',
        assetTemplate: {
            id: '',
            versionId: ''
        },
        assetsRequiringUpdate: false,
        flagDate: {
            enabled: false,
            period: FlagDates()[0].value
        }
    },
    assetTypes: [],
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'title',
        direction: 'asc',
        name: 'Title',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<AssetsStateModel>({
    name: 'assets',
    defaults: ASSETS_STATE_DEFAULT
})

@Injectable()
export class AssetsState {

    @Selector()
    static getAssetsState(state: AssetsStateModel) {
        return state;
    }

    @Selector()
    static getAssetTypes(state: AssetsStateModel) {
        return state.assetTypes;
    }

    @Selector()
    static getAssetFilters(state: AssetsStateModel) {
        return state.filters;
    }

    @Action(UpdateAssetFilters)
    public updateAssetTemplateFilters(ctx: StateContext<AssetsStateModel>, action: UpdateAssetFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetTypes)
    public updateAssetTypes(ctx: StateContext<AssetsStateModel>, action: UpdateAssetTypes) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.assetTypes = action.assetTypes;
                draft.filters.assetTemplate.id = draft.filters.assetTemplate.id ? draft.filters.assetTemplate.id : action.assetTypes[0].id;
                draft.filters.assetTemplate.versionId = draft.filters.assetTemplate.versionId ? draft.filters.assetTemplate.versionId : action.assetTypes[0].id;
            })
        );
    }

    @Action(UpdateAssetsPageSize)
    public updateAssetsPageSize(ctx: StateContext<AssetsStateModel>, action: UpdateAssetsPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetsSearchQuery)
    public updateAssetsSearchQuery(ctx: StateContext<AssetsStateModel>, action: UpdateAssetsSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetsActivePage)
    public updateAssetsActivePage(ctx: StateContext<AssetsStateModel>, action: UpdateAssetsActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetsSort)
    public updateAssetsSort(ctx: StateContext<AssetsStateModel>, action: UpdateAssetsSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = { ...action.sort, default: false };
                draft.filtered = true;
            })
        );
    }

    @Action(ResetAssetsState)
    public resetAssetsState(ctx: StateContext<AssetsStateModel>, action: ResetAssetsState) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = { ...ASSETS_STATE_DEFAULT.filters, assetTemplate: { id: draft.assetTypes[0].id, versionId: draft.assetTypes[0].id } };
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = { ...ASSETS_STATE_DEFAULT.sort };
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: ASSETS_STATE_DEFAULT.sort.prop, dir: ASSETS_STATE_DEFAULT.sort.direction }] });
        }
    }
}