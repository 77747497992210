import { Injectable } from "@angular/core";

import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";

import { UpdateUser } from "../actions/user";

import { User } from "@models/users";

export interface UserStateModel extends User { };

const USER_STATE_DEFAULT: UserStateModel = null;

@State<UserStateModel>({
    name: 'user',
    defaults: USER_STATE_DEFAULT
})

@Injectable()
export class UserState {
    @Selector()
    static getUser(state: UserStateModel) {
        return state;
    }

    @Action(UpdateUser)
    public updateClient(ctx: StateContext<UserStateModel>, action: UpdateUser) {
        ctx.setState(
            patch(action.user)
        );
    }

}