import { DatatableComponent } from '@siemens/ngx-datatable';

export class UpdateUsersPageSize {
    static readonly type = '[Users] Update Users Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateUsersSearchQuery {
    static readonly type = '[Users] Update Users Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateUsersActivePage {
    static readonly type = '[Users] Update Users Active Page';
    constructor(public activePage: number) { }
}

export class UpdateUsersSort {
    static readonly type = '[Users] Update Users Sort';
    constructor(public sort: { name: string, prop: string, direction: 'asc' | 'desc' }) { }
}

export class ResetUsersState {
    static readonly type = '[Users] Reset Users State';
    constructor(public table: DatatableComponent) { }
}
