import { DatatableComponent } from '@siemens/ngx-datatable';

import { Client } from '@models/clients';

export class AddClient {
    static readonly type = '[Clients] Add Client';
    constructor(public client: { id: string, name: string, client_logo: { logo_image: string, logo_name: string } }) { }
}

export class DeleteClient {
    static readonly type = '[Clients] Delete Client';
    constructor(public clientId: string) { }
}

export class UpdateClient {
    static readonly type = '[Clients] Update Client';
    constructor(public client: { id: string, name: string, client_logo: { logo_image: string, logo_name: string } }) { }
}

export class UpdateClients {
    static readonly type = '[Clients] Update Clients';
    constructor(public clients: Client[]) { }
}

export class SelectClient {
    static readonly type = '[Clients] Select Client';
    constructor(public clientId: string) { }
}

export class UpdateClientsPageSize {
    static readonly type = '[Clients] Update Clients Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateClientsSearchQuery {
    static readonly type = '[Clients] Update Clients Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateClientsActivePage {
    static readonly type = '[Clients] Update Clients Active Page';
    constructor(public activePage: number) { }
}

export class UpdateClientsSort {
    static readonly type = '[Clients] Update Clients Sort';
    constructor(public sort: { name: string, prop: string, direction: 'asc' | 'desc' }) { }
}

export class ResetClientsState {
    static readonly type = '[Clients] Reset Clients State';
    constructor(public table: DatatableComponent) { }
}

export class ResetClientSelectionState {
    static readonly type = '[Clients] Reset Client Selection State';
    constructor() { }
}
